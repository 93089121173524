import { YouTube } from './mdxComponents/YouTube.tsx'


#### 2025-01-22

- 商品の一覧画面から非表示や売り切れの設定を直接行えるようになりました 🎉「非表示」や「売り切れ」列のチェックボックスをクリックすることで、即座に設定を変更できます。
- 並替番号の入力欄を廃止しました。今後は一覧画面の「並べ替え」列のボタンをご利用ください。

#### 2025-01-14

- 商品やジャンルの並べ替えを一覧画面からボタンで行えるようになりました🎉
  - ボタンで並べ替えを行うと、自動で「並替番号」が振り直されます。なお、並替番号の入力欄は近日中に廃止する予定です。

#### 2025-01-09

紙メニューとして印刷した際に、商品がページをまたいで見切れてしまうケースがある問題を修正しました。

#### 2024-12-26

CSVインポート・エクスポートの機能を追加しました🎉
これにより、商品の一括登録・一括更新が可能になります。
インポート前には**必ずバックアップを作成**してからお試しください。

#### 2024-10-10

管理画面のUI/UXの細かな改善を行いました。

- 商品やジャンルの一覧画面で、名前が長い場合にセルが縦方向に無限に拡張して見づらくなっていました。このため、長文は省略表記するようにしました。
- 一覧画面の説明列を右端に移動し、より重要度の高い情報を左側に配置するようにしました。
- 商品の説明及び詳細説明が長文になる場合に、全体が見えず入力しづらい問題を解消しました。なお、デザインの制約上、商品の説明において改行は使用できないのは従来と変更ありません。
- これまでは商品を「非表示」にしたときに「売り切れ」の設定項目は隠されていましたが、迷いが生じるため常に表示するようにしました。

#### 2024-10-04 21:09

一部のお客様において管理画面がエラーになる問題が発生していましたが、復旧しました。ご迷惑をおかけし申し訳ございませんでした。

#### 2024-09-10

モバイル端末から商品の価格を入力した際に、文字が重複して入力される問題を修正しました。

#### 2024-08-16

- メニュー一覧画面のジャンル列をクリックすると、そのジャンルの詳細ページに遷移できるようになりました。
<YouTube url="https://www.youtube.com/embed/HpRjrS_BKJg?si=sQ2g70VipazTyXs0" />
