import {
  createUserWithEmailAndPassword,
  signInOrUpWithGoogle,
} from '@/features/auth/firebaseAuthUtils'
import { defaultScreenRoute } from '@/routes/routes'
import useIsMountedRef from '@/utils/useIsMountedRef'
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Link,
  type SxProps,
  TextField,
  type Theme,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import type { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'

interface Props {
  className?: string
}

const styles = {
  providerIcon: {
    marginRight: 2,
  },
  divider: {
    flexGrow: 1,
  },
  dividerText: {
    margin: 2,
  },
  googleButton: (theme) => ({
    backgroundColor: theme.palette.common.white,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  }),
} satisfies Record<string, SxProps<Theme>>

const SignupDetail: FC<Props> = ({ className, ...rest }) => {
  const navigate = useNavigate()

  const isMountedRef = useIsMountedRef()
  const { t } = useTranslation()

  const handleGoogleClick = async () => {
    try {
      await signInOrUpWithGoogle()
      navigate(defaultScreenRoute)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Button
        fullWidth={true}
        onClick={handleGoogleClick}
        size="large"
        sx={styles.googleButton}
        variant="contained"
      >
        <Box
          component="img"
          alt="Google"
          sx={styles.providerIcon}
          src="/static/images/google.svg"
        />
        <div>{t('ui_auth:Signup with Google')}</div>
      </Button>
      <Box alignItems="center" display="flex" mt={2}>
        <Divider sx={styles.divider} orientation="horizontal" />
        <Typography
          color="textSecondary"
          variant="body1"
          sx={styles.dividerText}
        >
          {t('or')}
        </Typography>
        <Divider sx={styles.divider} orientation="horizontal" />
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string()
            .min(7)
            .max(255)
            .required('Password is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await createUserWithEmailAndPassword(values.email, values.password)
            navigate(defaultScreenRoute)
          } catch (err) {
            console.error(err)
            if (isMountedRef.current) {
              setStatus({ success: false })
              if (err instanceof Error) {
                setErrors({ submit: err.message })
              }
              setSubmitting(false)
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate={true}
            className={className}
            onSubmit={handleSubmit}
            {...rest}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth={true}
              helperText={touched.email && errors.email}
              label={t('Email')}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />{' '}
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth={true}
              helperText={touched.password && errors.password}
              label={t('Password')}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error={true}>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth={true}
                size="large"
                type="submit"
                variant="contained"
              >
                {t('Register')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={3}>
        <Divider />
      </Box>
      <Box alignItems="center" display="flex" mt={3}>
        <Typography variant="body2" color="textSecondary">
          <Trans i18nKey="ui_auth:Checkout terms and privacy policy">
            <Link
              component="a"
              href="https://goodmenu.io/terms/"
              color="secondary"
              target="_blank"
            >
              Terms and conditions
            </Link>
            <Link
              component="a"
              href="https://goodmenu.io/privacyPolicy/"
              color="secondary"
              target="_blank"
            >
              Privacy policy
            </Link>
          </Trans>
        </Typography>
      </Box>
    </>
  )
}

export default SignupDetail
