import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import type { ReactNode } from 'react'

type MoveProductButtonType = 'up' | 'down' | 'top' | 'bottom'

type Props = {
  disabled: boolean
  genreName: string
  onClick: () => void
  type: 'up' | 'down' | 'top' | 'bottom'
}

const icons: Record<MoveProductButtonType, ReactNode> = {
  up: <KeyboardArrowUp />,
  down: <KeyboardArrowDown />,
  top: <KeyboardDoubleArrowUp />,
  bottom: <KeyboardDoubleArrowDown />,
}

/**
 * 商品の並べ替えを行うためのボタンの見た目部分を担当する
 */
export const MoveProductButton = (props: Props) => {
  const { disabled, genreName, onClick, type } = props

  const hint: Record<MoveProductButtonType, string> = {
    up: `「${genreName}」の中で一つ上に移動します`,
    down: `「${genreName}」の中で一つ下に移動します`,
    top: `「${genreName}」の先頭に移動します`,
    bottom: `「${genreName}」の最後に移動します`,
  }

  return (
    <Tooltip enterDelay={500} enterNextDelay={500} title={hint[type]}>
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={onClick}
        size="small"
      >
        {icons[type]}
      </IconButton>
    </Tooltip>
  )
}
