import { getToken } from '@/libraries/firebase/getToken.ts'
import { isPlaywrightTest } from '@/utils/isPlaywrightTest'
import ky from 'ky'

export const myKy = ky.create({
  hooks: {
    beforeRequest: [
      async (request) => {
        // Playwrightテスト時はレスポンスを無条件で返すようにモックするので、認証情報は不要
        if (isPlaywrightTest()) {
          return
        }

        const token = await getToken()
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`)
        }
      },
    ],
    afterResponse: [
      async (_request, _options, response) => {
        if (response.status >= 400 && response.status <= 599) {
          const message = await response.clone().json()
          console.warn('Response was not 200, message is: ', message)
        }
        return response
      },
    ],
  },
  timeout: 20000,
})
