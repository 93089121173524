import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationImportCsvDry() {
  return useMutation({
    mutationFn: async (csvString: string) => {
      return await trpcClient.csvImport.csvImportDry.mutate({
        csvString,
      })
    },
  })
}
