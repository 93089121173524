import type { FontType } from '@yuuniworks/suzume-trpc-types'

/**
 * API側の定数であるFontTypeに対応するフレンドリーな名前の一覧
 *
 * API側の定数であるFontTypeや、ユーザーのDB上のデータには、もう使っていないフォント設定も残っている。
 * なのでここではPartialにせざるをえない。
 */
export const googleFontSettings: Partial<Record<FontType, string>> = {
  kiwiMaru: 'Kiwi Maru',
  notoSans: 'Noto Sans JP (デフォルト)',
  notoSerif: 'Noto Serif JP',
  sawarabiGothic: 'Sawarabi Gothic',
  shipporiMincho: 'Shippori Mincho',
  yujiSyuku: 'Yuji Syuku',
  yuseiMagic: 'Yusei Magic',
  zenMaruGothic: 'Zen Maru Gothic',
}
