import App from '@/App'
import '@/libraries/nprogress/nprogress.css'
import { createRoot } from 'react-dom/client'
import invariant from 'tiny-invariant'

const container = document.getElementById('root')
invariant(container)
const root = createRoot(container)

root.render(<App />)
