import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router'

export const MenuitemListViewHeader = () => {
  const { t } = useTranslation()

  return (
    <Box sx={styles.container}>
      <Typography variant="h3" color="textPrimary">
        {t('Products')}
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        component={RouterLink}
        to="/app/management/menuitems/new"
        startIcon={
          <SvgIcon fontSize="small">
            <PlusCircleIcon />
          </SvgIcon>
        }
        sx={styles.addButton}
      >
        新規登録
      </Button>
    </Box>
  )
}

const styles = {
  container: { display: 'flex', alignItems: 'center' },
  addButton: { marginLeft: 'auto' },
} satisfies SxPropStyles
