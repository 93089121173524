import type { MaybeNewMenuitem } from '@/features/api/types'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationUpsertMenuitem() {
  return useMutation({
    mutationFn: async (unsavedItem: MaybeNewMenuitem): Promise<string> => {
      const commonParams = {
        description: unsavedItem.description,
        descriptionDetail: unsavedItem.description_detail,
        genreId: unsavedItem.genre || null,
        imgixFilename: unsavedItem.imgix_filename,
        isHidden: unsavedItem.is_hidden,
        name: unsavedItem.name,
        orderNum: unsavedItem.order_num,
        price: unsavedItem.price,
        soldOut: unsavedItem.sold_out,
      }

      if (unsavedItem.id) {
        const result = await trpcClient.product.update.mutate({
          ...commonParams,
          id: unsavedItem.id,
        })
        return result.id
      }

      const result = await trpcClient.product.create.mutate(commonParams)
      return result.id
    },
  })
}
