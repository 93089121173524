import { useMutationMoveGenre } from '@/features/genreList/api/useMutationMoveGenre.ts'
import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

/**
 * ジャンルの移動に関するロジックをまとめたカスタムフック
 */
export const useMoveGenre = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { isPending: isMoving, mutate: mutateMoveGenre } =
    useMutationMoveGenre()

  const moveGenre = async (
    genreId: string,
    direction: 'up' | 'down' | 'top' | 'bottom',
  ) => {
    mutateMoveGenre(
      {
        direction,
        genreId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.genresAll(),
          })
        },
        onError: (e) => {
          const customError = unwrapCustomErrorPayload(e)
          if (customError && customError.type === 'moveGenre.alreadyAtEdge') {
            enqueueSnackbar('すでに先頭又は末尾にあります', {
              variant: 'warning',
            })
            return
          }
          enqueueSnackbar(t('Error occurred'), {
            variant: 'error',
          })
        },
      },
    )
  }

  return {
    moveGenre,
    isMoving,
  }
}
