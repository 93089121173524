import { Box, type SxProps, type Theme } from '@mui/material'
import Quill, { type QuillOptions } from 'quill'
import { useEffect, useId } from 'react'
import 'quill/dist/quill.snow.css'
import invariant from 'tiny-invariant'

type Props = {
  /**
   * `<p><br></p>`のような形式のHTML文字列。初期値として利用する。
   */
  defaultValue: string
  /**
   * バリデーションエラー時などに枠線を赤くするため
   */
  redBorder?: boolean
  /**
   * シンプルさを保つため、Quillはアンコントロールドに使用する。
   * onChangeはユーザーによる変更を一方的に親に通知するためのコールバック。
   */
  onChange: (value: string) => void
}

const quillOptions: QuillOptions = {
  theme: 'snow',
  // ここで許可されていないと、いくらツールバーに表示(modules.toolbarに設定)されていても、使えない
  formats: ['bold', 'underline', 'color', 'align', 'link'],
  modules: {
    toolbar: [
      ['bold', 'underline', { color: [] }],
      [{ align: [] }],
      ['link'],
      ['clean'],
    ],
  },
}

/**
 * QuillをReactで使うためのラッパーコンポーネント
 *
 * React管理のDOMをQuillが乗っ取るという点で行儀は良くないが、シンプルさを優先した。
 */
const QuillEditor = (props: Props) => {
  const { defaultValue, redBorder = false, onChange } = props

  // 乗っ取るdivに一意なIDを付与する
  const targetElementId = useId()

  // Suspenseを使っているのでありえないが、データ消失を防ぐため一応確認しておく
  invariant(defaultValue !== undefined)

  // biome-ignore lint/correctness/useExhaustiveDependencies: 2回以上初期化すると絶対にバグるので
  useEffect(() => {
    const quill = new Quill(
      `[id="${targetElementId}"]`, // `#id`の形式で書くとエスケープ関連の問題によりエラーになるので注意
      quillOptions,
    )

    // HTML文字列をセットするときに、Quillの仕様に合わせて変換する
    // https://stackoverflow.com/a/61482805/6574720
    quill.setContents(quill.clipboard.convert({ html: defaultValue }))

    quill.on('text-change', (_a, _b, emitter) => {
      if (emitter !== 'user') {
        return
      }
      onChange(quill.root.innerHTML) // `<p><br></p>`のようなHTML文字列として取り出す
    })

    // イベントリスなの後始末などは特に不要であることを確認済み
  }, [])

  return (
    // Boxは赤枠線の表示と、QuillへのCSS適用の責務を負う
    <Box sx={[styles.container, redBorder && { border: '1px solid red' }]}>
      <div id={targetElementId} />
    </Box>
  )
}

const styles = {
  container: (theme) => ({
    // 文字を打つ部分のフォントを調整
    '.ql-container .ql-editor': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
    },
  }),
} satisfies SxProps<Theme>

export default QuillEditor
