import { useUserInfo } from '@/features/auth/state/authStatus.tsx'
import { Alert, AlertTitle, Box, Link } from '@mui/material'
import { format } from 'date-fns'
import { atom, useAtom } from 'jotai'
import { Link as RouterLink } from 'react-router'

// 画面遷移のたびに表示されるのを防ぐためにグローバルに管理
const isOpenAtom = atom(true)

/**
 * トライアル期間中に、有料プランへの早めの登録を促すメッセージを表示する
 */
export const ContractTrialNotification = () => {
  const { registration_date } = useUserInfo()
  // date-fnsで2024年01月01日の形式にする
  const formattedDate = format(registration_date, 'yyyy年MM月dd日')

  const [isOpen, setIsOpen] = useAtom(isOpenAtom)

  if (!isOpen) {
    return null
  }

  return (
    <Box mt={3}>
      <Alert
        variant="outlined"
        severity="info"
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <AlertTitle>トライアル期間中です</AlertTitle>
        登録日({formattedDate}
        )から1ヶ月経過すると使えなくなりますので、継続して利用される場合は
        <Link to="/app/account" component={RouterLink}>
          アカウント画面
        </Link>
        からの有料プランへのご登録をお早めにお願いいたします。
      </Alert>
    </Box>
  )
}
