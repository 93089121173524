import Page from '@/features/layout/Page'
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router'

const styles = {
  root: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 3,
    paddingTop: 8,
    paddingBottom: 8,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
}

const NotFoundView: FC = () => {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Page sx={styles.root} title="404: Not found">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          404: ページが見つかりませんでした
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/signin"
            variant="outlined"
          >
            サインイン画面に戻る
          </Button>
        </Box>
      </Container>
    </Page>
  )
}

export default NotFoundView
