import { useAuthStatus } from '@/features/auth/state/authStatus'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router'

/**
 * 認証済みでないと開けない画面を保護するコンポーネント。
 * ユーザーが非認証状態であればサインイン画面にリダイレクトする。
 */
export const ProtectedRoute = () => {
  const navigate = useNavigate()
  const authStatus = useAuthStatus()

  useEffect(() => {
    if (authStatus.type === 'unauthorized') {
      navigate('/signin')
    }
  }, [authStatus, navigate])

  return authStatus.type === 'authorized' ? <Outlet /> : null
}
