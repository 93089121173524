import type { SxPropStyles } from '@/libraries/mui/muiTypes'
import { Button, Link, ListItem } from '@mui/material'
import type { FC, ReactNode } from 'react'
import type { Icon } from 'react-feather'
import { NavLink } from 'react-router'

interface NavItemProps {
  children?: ReactNode
  href?: string
  isExternalLink?: boolean
  icon?: Icon
  open?: boolean
  title: ReactNode
}

const styles = {
  container: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: (theme) => ({
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 'normal',
    gap: '0.5rem',
    '&.active': {
      color: theme.palette.secondary.main,
    },
  }),
} satisfies SxPropStyles

const NavItem: FC<NavItemProps> = ({
  children,
  href,
  isExternalLink,
  icon: Icon,
  open: openProp,
  title,
  ...rest
}) => {
  const iconAndTitle = (
    <>
      {Icon && <Icon size="20" />}
      {title}
    </>
  )

  return (
    <ListItem sx={styles.container} disableGutters={true} {...rest}>
      {isExternalLink ? (
        <Button
          sx={styles.button}
          component={Link}
          target="_blank"
          href={href}
          rel="noreferrer"
        >
          {iconAndTitle}
        </Button>
      ) : (
        <Button sx={styles.button} component={NavLink} to={href || ''}>
          {iconAndTitle}
        </Button>
      )}
    </ListItem>
  )
}

export default NavItem
