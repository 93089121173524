import { config } from '@/config.ts'
import { myKy } from '@/features/api/myKy.ts'
import Page from '@/features/layout/Page.tsx'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Box, Button, Container, Typography } from '@mui/material'
import { format } from 'date-fns'

export const CsvExport = () => {
  const onClickExport = async () => {
    const url = `${config.apiDomain}/product/csv-export`
    const blob = await myKy.get(url).blob()

    // 以下の処理はChatGPTが生成したコードである。csvファイルをダウンロードするための処理である。
    const tempCsvUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = tempCsvUrl
    const formattedDate = format(new Date(), 'yyyyMMdd-HHmmss')
    link.download = `export-${formattedDate}.csv` // ダウンロードファイル名
    link.click()
    link.remove()
    window.URL.revokeObjectURL(tempCsvUrl)
  }

  return (
    <Page sx={styles.root} title="CSVエクスポート">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          CSVエクスポート
        </Typography>
        <Typography variant="body1" mt={3} lineHeight={2}>
          ・登録している商品をCSVファイルとしてエクスポートできます。
          <br />
          ・エクスポートしたファイルは、ExcelやGoogleスプレッドシートで一括編集が可能です。
          <br />
          ・編集が終わったCSVファイルを再度インポートすると、大量の商品情報を効率よくまとめて更新できます。
        </Typography>
        <Box mt={3}>
          <Button variant="contained" onClick={onClickExport}>
            エクスポート
          </Button>
        </Box>
      </Container>
    </Page>
  )
}

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  card: {
    marginY: 2,
    padding: 2,
  },
} satisfies SxPropStyles
