import { resetPassword } from '@/features/auth/firebaseAuthUtils'
import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { Box, Button, Divider, FormHelperText, TextField } from '@mui/material'
import { Formik } from 'formik'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import useIsMountedRef from '@/utils/useIsMountedRef'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'

interface Props {
  className?: string
}

const ResetPasswordForm: FC<Props> = ({ className, ...rest }) => {
  const isMountedRef = useIsMountedRef()
  const { confirm } = useConfirmation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await resetPassword(values.email)

            await confirm({
              variant: 'info',
              title: t('Success'),
              description: t('ui_auth:Password reset success message'),
            })
            navigate('/signin')

            if (isMountedRef.current) {
              setStatus({ success: true })
              setSubmitting(false)
            }
          } catch (err) {
            console.error(err)
            if (isMountedRef.current) {
              setStatus({ success: false })
              if (err instanceof Error) {
                setErrors({ submit: err.message })
              }
              setSubmitting(false)
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate={true}
            className={className}
            onSubmit={handleSubmit}
            {...rest}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth={true}
              helperText={touched.email && errors.email}
              label={t('Email')}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error={true}>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth={true}
                size="large"
                type="submit"
                variant="contained"
              >
                {t('ui_auth:Reset password')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={3}>
        <Divider />
      </Box>
    </>
  )
}

export default ResetPasswordForm
