import invariant from 'tiny-invariant'

export const readCsvFileAsString = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      invariant(
        typeof reader.result === 'string',
        'csvファイル以外は想定しない',
      )
      resolve(reader.result)
    }
    reader.onerror = () => {
      reject(`ファイルの読み込みに失敗しました: ${reader.error})`)
    }
  })
}
