import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type MutationVariables = {
  productId: string
  isHidden: boolean
}

/**
 * 商品の表示非表示に関するロジックをまとめたカスタムフック
 */
export const useUpdateProductVisibility = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const { mutate } = useMutation({
    mutationFn: async ({
      productId,
      isHidden,
    }: MutationVariables): Promise<void> => {
      await trpcClient.product.updateVisibility.mutate({
        productId,
        isHidden,
      })
    },
  })

  const updateProductVisibility = async (
    productId: string,
    productName: string,
    isHidden: boolean,
  ) => {
    mutate(
      {
        productId: productId,
        isHidden: isHidden,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.menuitemsAll(),
          })
          const message = isHidden
            ? 'を非表示にしました'
            : 'の非表示を解除しました'
          enqueueSnackbar(`「${productName}」${message}`, {
            variant: 'success',
          })
        },
        onError: () => {
          enqueueSnackbar(t('Error occurred'), {
            variant: 'error',
          })
        },
      },
    )
  }

  return {
    updateProductVisibility,
  }
}
