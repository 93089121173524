import { localStorageKeys } from '@/constants/localStorageKeys'
import { atom, useAtom } from 'jotai'

/**
 * お知らせを更新したときは以下の値も合わせて更新する
 */
const LAST_ANNOUNCEMENT_DATE: string = '20250122'

const isReadAtom = atom(
  (localStorage.getItem(localStorageKeys.lastAnnouncementDate) ?? '') ===
    LAST_ANNOUNCEMENT_DATE,
)

export const useAnnouncementManager = () => {
  const [isRead, setIsRead] = useAtom(isReadAtom)

  const markAsRead = () => {
    if (isRead) {
      return
    }
    localStorage.setItem(
      localStorageKeys.lastAnnouncementDate,
      LAST_ANNOUNCEMENT_DATE,
    )
    setIsRead(true)
  }

  return { isRead, markAsRead }
}
