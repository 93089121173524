import { useMutationCreateCheckoutSession } from '@/features/account/api/useMutationCreateCheckoutSession'
import { planSettings } from '@/features/account/planSettings'
import { css } from '@emotion/react'
import { Button, Typography } from '@mui/material'
import type { FC } from 'react'

type Props = {
  className?: string
}

export const SubscriptionNew: FC<Props> = (props) => {
  const { isPending, mutate, isError } = useMutationCreateCheckoutSession()

  const subscribeNewPlan = () => {
    mutate(planSettings.basic.priceId)
  }

  return (
    <div {...props}>
      <Button
        disabled={isPending}
        variant="contained"
        onClick={subscribeNewPlan}
      >
        サブスクリプションを契約する
      </Button>
      {isError && (
        <Typography color="error" variant="caption" css={styles.errorText}>
          エラーが発生しました
        </Typography>
      )}
    </div>
  )
}

const styles = {
  errorText: css`
    margin-left: 5px;
  `,
}
