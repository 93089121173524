import { ClampText } from '@/components/ClampText.tsx'
import { MoveGenreButton } from '@/features/genreList/moveGenre/MoveGenreButton.tsx'
import { useMoveGenre } from '@/features/genreList/moveGenre/useMoveGenre.ts'
import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { type ChangeEvent, startTransition, useEffect, useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router'

import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { useMutationBulkDeleteGenres } from '@/features/genreList/api/useMutationBulkDeleteGenres'
import { useQueryGenres } from '@/features/genreList/api/useQueryGenres'
import { useGenresQueryCondition } from '@/features/genreList/useGenresQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useDebounce } from '@/utils/useDebounce'

export const GenreListViewResults = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { condition: queryCondition, setSearchWord } = useGenresQueryCondition()
  const { confirm } = useConfirmation()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedGenres, setSelectedGenres] = useState<string[]>([])

  const [searchInputValue, setSearchInputValue] = useState<string>(
    // restore previous value on navigation
    queryCondition.searchWord || '',
  )
  const debouncedSearchWord = useDebounce<string>(searchInputValue, 500)
  useEffect(() => {
    startTransition(() => {
      setSearchWord(debouncedSearchWord)
    })
  }, [debouncedSearchWord, setSearchWord])

  const genresQuery = useQueryGenres(queryCondition)
  const { data: genres } = genresQuery

  const { isPending: isDeleting, mutate: deletionMutate } =
    useMutationBulkDeleteGenres()

  const { isMoving, moveGenre } = useMoveGenre()

  const handleSelectAllGenres = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedGenres(
      event.target.checked ? genres.map((genre) => genre.id) : [],
    )
  }

  const handleSelectOneGenre = (genreId: string): void => {
    if (selectedGenres.includes(genreId)) {
      setSelectedGenres((prevSelected) =>
        prevSelected.filter((id) => id !== genreId),
      )
    } else {
      setSelectedGenres((prevSelected) => [...prevSelected, genreId])
    }
  }

  const deleteSelectedItems = async () => {
    const message = t('ui_genres:multiple genres deletion confirmation', {
      count: selectedGenres.length,
    })
    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: message,
      title: t('Deletion'),
      variant: 'danger',
    })
    if (yes) {
      deletionMutate(selectedGenres, {
        onSuccess: (result) => {
          queryClient.invalidateQueries({ queryKey: queryKeys.genresAll() })
          const message = t('ui_genres:Deleted genres', {
            count: result.count,
          })
          enqueueSnackbar(message, { variant: 'success' })
          setSelectedGenres([])
        },
        onError: () => {
          enqueueSnackbar('エラーが発生しました', { variant: 'error' })
        },
      })
    }
  }

  const enableBulkOperations = selectedGenres.length > 0
  const isSomeGenreSelected =
    selectedGenres.length > 0 && selectedGenres.length < genres.length
  const isEveryGenreSelected =
    genres.length > 0 && selectedGenres.length === genres.length
  const isFiltering = !!queryCondition.searchWord
  const hasGenres = genres.length > 0

  if (!(isFiltering || hasGenres)) {
    return (
      <Typography color="textPrimary">
        {t(
          'ui_genres:No genres have been registered yet. Click the button to register your first genre.',
        )}
      </Typography>
    )
  }

  return (
    <>
      <Box sx={styles.searchArea}>
        <TextField
          sx={styles.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: searchInputValue ? (
              <IconButton
                onClick={() => {
                  setSearchInputValue('')
                  // skip debounsing and show all items immediately
                  setSearchWord('')
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            ) : null,
          }}
          onChange={(e) => {
            setSearchInputValue(e.target.value)
          }}
          placeholder={t('Search')}
          size="small"
          value={searchInputValue}
          variant="outlined"
        />
      </Box>
      <Card sx={styles.tableArea}>
        {enableBulkOperations && (
          <Box sx={styles.bulkOperations}>
            <Box sx={styles.bulkActions}>
              <Checkbox
                checked={isEveryGenreSelected}
                sx={styles.bulkActionCheckbox}
                indeterminate={isSomeGenreSelected}
                onChange={handleSelectAllGenres}
              />
              <Button
                disabled={isDeleting}
                variant="outlined"
                sx={styles.bulkAction}
                onClick={deleteSelectedItems}
                size="small"
              >
                {t('Delete')}
              </Button>
            </Box>
          </Box>
        )}
        <Paper
          css={css`
          overflow-x: auto;
        `}
        >
          <Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isEveryGenreSelected}
                      indeterminate={isSomeGenreSelected}
                      onChange={handleSelectAllGenres}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ ...styles.cellCommon, minWidth: '200px' }}
                    sortDirection="desc"
                  >
                    {t('Name')}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...styles.cellCommon,
                      minWidth: '120px',
                      paddingLeft: '18px', // ずれて見えるので手動調整
                    }}
                  >
                    並べ替え
                  </TableCell>
                  <TableCell sx={{ ...styles.cellCommon, minWidth: '80px' }}>
                    {t('Hidden')}
                  </TableCell>
                  <TableCell
                    sx={{ ...styles.cellCommon, minWidth: '250px' }}
                    sortDirection="desc"
                  >
                    {t('Additional description')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {genres.map((genre) => {
                  const isGenreSelected = selectedGenres.includes(genre.id)

                  return (
                    <TableRow
                      hover={true}
                      key={genre.id}
                      selected={isGenreSelected}
                    >
                      <TableCell sx={styles.cellCommon} padding="checkbox">
                        <Checkbox
                          checked={isGenreSelected}
                          onChange={() => handleSelectOneGenre(genre.id)}
                          value={isGenreSelected}
                        />
                      </TableCell>
                      <TableCell sx={styles.cellCommon}>
                        <Box minHeight={40} display="flex" alignItems="center">
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/app/management/genres/${genre.id}`}
                            variant="h6"
                          >
                            <ClampText>{genre.name}</ClampText>
                          </Link>
                        </Box>
                      </TableCell>
                      <TableCell sx={styles.cellCommon} align="right">
                        <Box display="flex">
                          <MoveGenreButton
                            disabled={isMoving}
                            onClick={() => moveGenre(genre.id, 'top')}
                            type="top"
                          />
                          <MoveGenreButton
                            disabled={isMoving}
                            onClick={() => moveGenre(genre.id, 'up')}
                            type="up"
                          />
                          <MoveGenreButton
                            disabled={isMoving}
                            onClick={() => moveGenre(genre.id, 'down')}
                            type="down"
                          />
                          <MoveGenreButton
                            disabled={isMoving}
                            onClick={() => moveGenre(genre.id, 'bottom')}
                            type="bottom"
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={styles.cellCommon}>
                        {genre.is_hidden ? t('Yes') : ''}
                      </TableCell>
                      <TableCell
                        sx={{
                          ...styles.cellCommon,
                          // こうしとかないとなぜかClampが効かない
                          maxWidth: 250,
                        }}
                      >
                        <ClampText>{genre.description}</ClampText>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Card>
    </>
  )
}

const styles = {
  searchArea: { background: 'white', width: '100%', marginTop: 2 },
  tableArea: { marginTop: 2 },
  // 全てのセルのパディングを減らす
  cellCommon: {
    paddingX: 1,
    paddingY: 0.75,
  },
  queryField: {
    width: '100%',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: '7px',
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'background.default',
  },
  bulkActionCheckbox: {
    margin: '-3px 0',
  },
  bulkAction: {
    marginLeft: 2,
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}
