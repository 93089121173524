import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import type { ReactNode } from 'react'

type MoveGenreButtonType = 'up' | 'down' | 'top' | 'bottom'

type Props = {
  disabled: boolean
  onClick: () => void
  type: 'up' | 'down' | 'top' | 'bottom'
}

const icons: Record<MoveGenreButtonType, ReactNode> = {
  up: <KeyboardArrowUp />,
  down: <KeyboardArrowDown />,
  top: <KeyboardDoubleArrowUp />,
  bottom: <KeyboardDoubleArrowDown />,
}

/**
 * ジャンルの並べ替えを行うためのボタンの見た目部分を担当する
 */
export const MoveGenreButton = (props: Props) => {
  const { disabled, onClick, type } = props

  const hint: Record<MoveGenreButtonType, string> = {
    up: '一つ上に移動します',
    down: '一つ下に移動します',
    top: '先頭に移動します',
    bottom: '最後に移動します',
  }

  return (
    <Tooltip enterDelay={500} enterNextDelay={500} title={hint[type]}>
      <IconButton
        color="primary"
        disabled={disabled}
        onClick={onClick}
        size="small"
      >
        {icons[type]}
      </IconButton>
    </Tooltip>
  )
}
