import { css } from '@emotion/react'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

type Props = {
  onFileSelected: (file: File) => void
}

/**
 * ユーザーがアップロードしたいCSVファイルを選択するためのコンポーネント
 */
export const CsvDropZone = ({ onFileSelected }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // MIMEがcsv以外のファイルをドロップすると空になる
      if (!acceptedFiles[0]) {
        alert('このファイルはCSVファイルではありません')
        return
      }
      onFileSelected(acceptedFiles[0])
    },
    [onFileSelected],
  )

  // react-dropzone の設定
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    multiple: false,
  })

  return (
    <div {...getRootProps()} css={styles.dropZone}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>ここにドロップしてください</p>
      ) : (
        <p>ここをクリックするか、CSVファイルをここにドロップしてください</p>
      )}
    </div>
  )
}

const styles = {
  dropZone: css`
    align-items: center;
    background: white;
    border: 2px dashed #ccc;
    cursor: pointer;
    display: flex;
    justify-content: center;
    max-width: 400px;
    min-height: 200px;
    padding: 20px;
    text-align: center;
  `,
}
