import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryUserStats = () => {
  return useSuspenseQuery({
    queryKey: queryKeys.statsUser(),
    queryFn: () => {
      return trpcClient.stats.getUserStats.query()
    },
  })
}
