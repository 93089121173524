import { CsvDropZone } from '@/features/csvImport/CsvDropZone.tsx'
import { useMutationImportCsv } from '@/features/csvImport/api/useMutationImportCsv.ts'
import { useMutationImportCsvDry } from '@/features/csvImport/api/useMutationImportCsvDry.ts'
import { readCsvFileAsString } from '@/features/csvImport/readCsvFileAsString.ts'
import Page from '@/features/layout/Page.tsx'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import { Box, Button, Container, Typography } from '@mui/material'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router'
import invariant from 'tiny-invariant'

type Step =
  | { type: 'initial' }
  | { type: 'fileSelected'; file: File }
  | { type: 'importError'; message: string }
  | { type: 'importSuccess' }

export const CsvImport = () => {
  const [step, setStep] = useState<Step>({
    type: 'initial',
  })

  const { mutateAsync: importCsvFile } = useMutationImportCsv()
  const { mutateAsync: importCsvFileDry } = useMutationImportCsvDry()

  const onFileSelected = (file: File) => {
    setStep({ type: 'fileSelected', file })
  }

  const handleCsvImportError = (e: unknown) => {
    const payload = unwrapCustomErrorPayload(e)
    switch (payload?.type) {
      case 'csvImport.failure': {
        setStep({ type: 'importError', message: payload.message })
        return
      }
      default: {
        setStep({
          type: 'importError',
          message: '不明なエラーが発生しました',
        })
        throw new Error('CSVインポート時の不明なエラー', { cause: e })
      }
    }
  }

  const onImportButtonClick = async () => {
    invariant(step.type === 'fileSelected')
    const csvString = await readCsvFileAsString(step.file)

    // ドライランと本実行は同じエラー処理を行うため、あえてmutateAsync+try-catchで処理した
    try {
      const { newItemCount, updatedItemCount } =
        await importCsvFileDry(csvString)

      const cancel = !confirm(
        `インポートの失敗は取り消しができません。\n🚨必要に応じて事前にバックアップを作成してください🚨。\n\n新規作成される商品: ${newItemCount}件\n更新される商品: ${updatedItemCount}件\n\nインポートを続行してよろしいですか？`,
      )
      if (cancel) {
        return
      }
      await importCsvFile(csvString)
    } catch (e) {
      handleCsvImportError(e)
      return
    }

    alert('インポートに成功しました')
    setStep({ type: 'importSuccess' })
  }

  return (
    <Page sx={styles.root} title="CSVインポート">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          CSVインポート
        </Typography>

        <Typography variant="body1" mt={3} lineHeight={2}>
          ・CSVファイルにより、商品を一括登録したり、一括更新することができます。
          <br />
          ・使い方は
          <a
            href="https://fern-tank-8d6.notion.site/CSV-167a922f625e8094978aee8594425182"
            target="_blank"
            rel="noreferrer"
          >
            マニュアル
          </a>
          をご参照ください。
          <br />
          ・必要に応じて
          <Box component="span" sx={styles.cautionMessage}>
            事前に<RouterLink to="/app/backup">バックアップ</RouterLink>
            を作成してください。
          </Box>
          インポートの失敗は取り消しができません。
        </Typography>

        <Box mt={3} mb={3}>
          <CsvDropZone onFileSelected={(f) => onFileSelected(f)} />
        </Box>

        {step.type === 'fileSelected' && (
          <>
            <Typography variant="body1" color="textPrimary">
              選択したファイル名: {step.file.name}
            </Typography>
            <Button
              sx={{ mt: 2 }}
              onClick={onImportButtonClick}
              variant="contained"
            >
              インポートする
            </Button>
          </>
        )}

        {step.type === 'importSuccess' && (
          <Typography variant="body1" color="success">
            インポートに成功しました。
          </Typography>
        )}

        {step.type === 'importError' && (
          <>
            <Typography variant="body1" color="error">
              エラーが発生しました。詳細は以下の通りです。
            </Typography>
            <Box component={'code'} sx={styles.errorBox}>
              {step.message}
            </Box>
          </>
        )}
      </Container>
    </Page>
  )
}

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  card: {
    marginY: 2,
    padding: 2,
  },
  cautionMessage: { color: 'red', fontWeight: 'bold' },
  errorBox: {
    mt: 2,
    padding: 3,
    background: '#f0f0f0',
    fontSize: 12,
    display: 'block',
  },
} satisfies SxPropStyles
