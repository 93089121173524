import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type MutationVariables = {
  productId: string
  soldOut: boolean
}

/**
 * 商品の売り切れ設定に関するロジックをまとめたカスタムフック
 */
export const useUpdateProductSoldOut = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const { mutate } = useMutation({
    mutationFn: async ({
      productId,
      soldOut,
    }: MutationVariables): Promise<void> => {
      await trpcClient.product.updateSoldOut.mutate({
        productId,
        soldOut,
      })
    },
  })

  const updateProductSoldOut = async (
    productId: string,
    productName: string,
    soldOut: boolean,
  ) => {
    mutate(
      {
        productId,
        soldOut,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.menuitemsAll(),
          })
          const message = soldOut
            ? 'を売り切れにしました'
            : 'の売り切れを解除しました'
          enqueueSnackbar(`「${productName}」${message}`, {
            variant: 'success',
          })
        },
        onError: () => {
          enqueueSnackbar(t('Error occurred'), {
            variant: 'error',
          })
        },
      },
    )
  }

  return {
    updateProductSoldOut,
  }
}
