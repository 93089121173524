import AccountView from '@/features/account/AccountView'
import { Admin } from '@/features/admin/Admin.tsx'
import Announcement from '@/features/announcement/Announcement'
import AnnouncementPast from '@/features/announcement/AnnouncementPast'
import ResetPassword from '@/features/authScreen/ResetPassword'
import SignIn from '@/features/authScreen/Signin'
import SignUp from '@/features/authScreen/Signup'
import BackupView from '@/features/backup/BackupView'
import { CsvExport } from '@/features/csvExport/CsvExport.tsx'
import { CsvImport } from '@/features/csvImport/CsvImport.tsx'
import DeploymentView from '@/features/deployment/DeploymentView'
import GenreDetailView from '@/features/genreDetail/GenreDetailView'
import GenreListView from '@/features/genreList/GenreListView'
import HealthCheckView from '@/features/health_check/HealthCheckView'
import NotFoundView from '@/features/layout/NotFoundView'
import { DashboardLayout } from '@/features/layoutDashboard'
import MenuSetting from '@/features/menuSetting/MenuSetting'
import MenuitemDetailView from '@/features/menuitemDetail/MenuitemDetailView'
import MenuitemListView from '@/features/menuitemList/MenuitemListView'
import SupportView from '@/features/support/SupportView'
import SentryExamplePage from '@/libraries/sentry/SentryExamplePage'
import { ProtectedRoute } from '@/routes/ProtectedRoute'
import { Navigate, Route, Routes } from 'react-router'

// 認証状態によらず常に表示するページ
export const publicRoutes = ['/health_check']

// サインイン後に表示するページ
export const defaultScreenRoute = '/app/management/menuitems'

export const renderRoutes = () => {
  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />

      <Route path="/signup" element={<SignUp />} />

      <Route path="/resetPassword" element={<ResetPassword />} />

      <Route path="/404" element={<NotFoundView />} />

      <Route path="/sentry-example-page" element={<SentryExamplePage />} />

      <Route path="/health_check" element={<HealthCheckView />} />

      {/* ここから下はログイン後の画面 */}

      <Route element={<ProtectedRoute />}>
        <Route element={<DashboardLayout />}>
          <Route path="/app/account" element={<AccountView />} />

          <Route path="/app/admin" element={<Admin />} />

          <Route path="/app/announcement" element={<Announcement />} />

          <Route path="/app/csv-import" element={<CsvImport />} />

          <Route path="/app/csv-export" element={<CsvExport />} />

          <Route path="/app/pastReleaseNotes" element={<AnnouncementPast />} />

          <Route path="/app/management/menu" element={<MenuSetting />} />

          <Route path="/app/management/genres" element={<GenreListView />} />

          <Route
            path="/app/management/genres/:genreId"
            element={<GenreDetailView />}
          />

          <Route
            path="/app/management/menuitems"
            element={<MenuitemListView />}
          />

          <Route
            path="/app/management/menuitems/:menuitemId"
            element={<MenuitemDetailView />}
          />

          <Route
            path="/app/management/previewMenu"
            element={<DeploymentView />}
          />

          <Route path="/app/backup" element={<BackupView />} />

          <Route path="/app/support" element={<SupportView />} />
        </Route>
      </Route>

      {/* ここから下はリダイレクト系 */}

      <Route
        // 何処にいかないのも不細工なので
        path="/"
        element={<Navigate to="/signin" />}
      />

      <Route
        //これは最後にくるようにしないといけない
        path="*"
        element={<Navigate to="/404" />}
      />
    </Routes>
  )
}
