import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

type Args = {
  genreId: string
  direction: 'up' | 'down' | 'top' | 'bottom'
}
export function useMutationMoveGenre() {
  return useMutation({
    mutationFn: async ({ genreId, direction }: Args): Promise<void> => {
      await trpcClient.genre.moveGenre.mutate({
        genreId,
        direction,
      })
    },
  })
}
