import { ContractExpiredNotification } from '@/features/account/forcePayment/ContractExpiredNotification.tsx'
import { ContractTrialNotification } from '@/features/account/forcePayment/ContractTrialNotification.tsx'
import { useUserInfo } from '@/features/auth/state/authStatus.tsx'
import Page from '@/features/layout/Page'
import { MenuitemListViewCautionMailVerification } from '@/features/menuitemList/MenuitemListViewCautionMailVerification'
import { MenuitemListViewHeader } from '@/features/menuitemList/MenuitemListViewHeader'
import { MenuitemListViewResults } from '@/features/menuitemList/MenuitemListViewResults'
import { Box, Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

const styles = {
  container: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}

const MenuitemListView: FC = () => {
  const { t } = useTranslation()
  const userInfo = useUserInfo()

  if (userInfo.contractStatus === 'expired') {
    return (
      <Box mx={4}>
        <ContractExpiredNotification />
      </Box>
    )
  }

  return (
    <Page sx={styles.container} title={t('Products')}>
      <Container maxWidth={false}>
        <MenuitemListViewHeader />
        {userInfo.contractStatus === 'trial' && <ContractTrialNotification />}
        <MenuitemListViewCautionMailVerification />
        <MenuitemListViewResults />
      </Container>
    </Page>
  )
}

export default MenuitemListView
