import { config } from '@/config.ts'
import { useQueryProductStats } from '@/features/admin/productStats/api/useQueryProductStats.ts'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Card, Typography } from '@mui/material'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { formatInTimeZone } from 'date-fns-tz'
import { ja } from 'date-fns/locale/ja'

export const ProductStats = () => {
  const { data } = useQueryProductStats()

  console.log(data)

  return (
    <Card sx={styles.card}>
      <Typography variant={'h5'} mb={2}>
        商品の更新状況
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>更新日時</TableCell>
              <TableCell>商品名</TableCell>
              <TableCell>店名</TableCell>
              <TableCell>QR</TableCell>
              <TableCell>変更差分</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={`${row.qrCodeId}_${row.productName}_${row.loggedAt}`}
              >
                <TableCell sx={{ ...styles.cell }}>
                  {formatInTimeZone(
                    row.loggedAt,
                    'Asia/Tokyo',
                    'M月dd日 HH:mm:ss.SSS',
                    { locale: ja },
                  )}
                </TableCell>
                <TableCell sx={{ ...styles.cell, ...styles.ellipsis }}>
                  {row.productName}
                </TableCell>
                <TableCell sx={{ ...styles.cell, ...styles.ellipsis }}>
                  {row.shopName}
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>
                  <a
                    target="_blank"
                    href={`${config.visitorSiteDomain}/qrcode/${row.qrCodeId}`}
                    rel="noreferrer"
                  >
                    {row.qrCodeId}
                  </a>
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>{row.patch}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

const styles = {
  card: {
    padding: 2,
  },
  cell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ellipsis: {
    maxWidth: 200, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} satisfies SxPropStyles
