import { config } from '@/config'
import { getToken } from '@/libraries/firebase/getToken.ts'
import { isPlaywrightTest } from '@/utils/isPlaywrightTest'
import { createTRPCClient, httpLink } from '@trpc/client'
import type { TrpcRouter } from '@yuuniworks/suzume-trpc-types'

export const trpcClient = createTRPCClient<TrpcRouter>({
  links: [
    httpLink({
      url: `${config.apiDomain}/trpc`,

      // You can pass any HTTP headers you wish here
      async headers() {
        // Playwrightテスト時はレスポンスを無条件で返すようにモックするので、認証情報は不要
        if (isPlaywrightTest()) {
          return {}
        }

        const token = await getToken()
        if (!token) {
          return {}
        }

        return {
          Authorization: `Bearer ${token}`,
        }
      },
    }),
  ],
})
