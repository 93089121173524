import { FieldTitle } from '@/components/FieldTitle'
import { FormikErrors } from '@/components/FormikErrors'
import {
  type Genre,
  type MaybeNewGenre,
  genreLayoutTypes,
} from '@/features/api/types'
import { useMutationDeleteGenre } from '@/features/genreDetail/api/useMutationDeleteGenre'
import { useMutationUpsertGenre } from '@/features/genreDetail/api/useMutationUpsertGenre'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Switch,
  TextField,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import invariant from 'tiny-invariant'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'
import { useConfirmation } from '../confirmation/ConfirmationContext.tsx'

interface Props {
  existingGenre?: Genre
  isNew: boolean
}

export const GenreDetailViewForm: FC<Props> = ({
  existingGenre,
  isNew,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { confirm } = useConfirmation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { isPending: isUpdating, mutate: upsertGenre } =
    useMutationUpsertGenre()

  const { isPending: isDeleting, mutate: mutateDelete } =
    useMutationDeleteGenre()

  const deleteGenre = async () => {
    // もうちょっときれいな方法でやりたい
    invariant(existingGenre, '削除対象のジャンルIDが取得できませんでした')

    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: t('ui_genres:deletion confirmation', {
        name: existingGenre.name,
      }),
      title: t('Deletion'),
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    mutateDelete(existingGenre.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.genresAll() })
        enqueueSnackbar(t('Deleted'), { variant: 'success' })
        navigate('/app/management/genres/', { replace: true })
      },
      onError: () => {
        enqueueSnackbar('エラーが発生しました', { variant: 'error' })
      },
    })
  }

  let initialValues: MaybeNewGenre
  if (isNew) {
    initialValues = {
      description: '',
      // id
      is_hidden: false,
      layout_type: 'default',
      name: '',
    }
  } else {
    initialValues = {
      // @ts-expect-error TS18048
      description: existingGenre.description,
      // @ts-expect-error TS18048
      id: existingGenre.id,
      // @ts-expect-error TS18048
      is_hidden: existingGenre.is_hidden,
      // @ts-expect-error TS18048
      layout_type: existingGenre.layout_type,
      // @ts-expect-error TS18048
      name: existingGenre.name,
    }
  }

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik<MaybeNewGenre>({
      enableReinitialize: true,
      initialValues,
      onSubmit: (formValues) => {
        upsertGenre(formValues, {
          onSuccess: async (savedGenreId) => {
            const message = isNew ? t('Added') : t('Updated')
            enqueueSnackbar(message, { variant: 'success' })
            await queryClient.invalidateQueries({
              queryKey: queryKeys.genre(savedGenreId),
            })
            if (isNew) {
              navigate(`/app/management/genres/${savedGenreId}/`, {
                replace: true,
              })
            }
          },
          onError: () => {
            enqueueSnackbar('エラーが発生しました', { variant: 'error' })
          },
        })
      },
      validationSchema: Yup.object().shape({
        description: Yup.string().max(200),
        name: Yup.string().max(100).required(),
      }),
    })

  return (
    <form onSubmit={handleSubmit} {...rest}>
      <Card>
        <CardContent>
          {/* グリッド表示 */}
          <Box sx={styles.grid}>
            {/* ジャンル名 */}
            <Box sx={styles.cellGenre}>
              <FieldTitle title={`${t('ui_genres:Genre name')} *`} />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth={true}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.name}
                  variant="outlined"
                />
              </Box>
            </Box>

            {/* 説明 */}
            <Box sx={styles.cellDescription}>
              <FieldTitle
                title={t('Additional description')}
                tooltipText={t('ui_genres:Hint for Additional description')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth={true}
                  multiline={true}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
            </Box>

            {/* レイアウトの種類 */}
            <Box sx={styles.cellLayout}>
              <FieldTitle title={t('ui_genres:Layout type')} />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.layout_type && errors.layout_type)}
                  fullWidth={true}
                  name="layout_type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select={true}
                  SelectProps={{ native: true }}
                  value={values.layout_type}
                  variant="outlined"
                >
                  {genreLayoutTypes.map((genreLayoutType) => {
                    const label = t(`ui_genres:layout_type_${genreLayoutType}`)
                    return (
                      <option key={genreLayoutType} value={genreLayoutType}>
                        {label}
                      </option>
                    )
                  })}
                </TextField>
              </Box>
            </Box>

            {/* 非表示 */}
            <Box sx={styles.cellHidden}>
              <Box component="label" display="flex" alignItems="center">
                <Switch
                  checked={values.is_hidden}
                  color="secondary"
                  edge="start"
                  name="is_hidden"
                  onChange={(e) => {
                    // 商品と間違えて非表示にしちゃう人が多いので一応確認しとく
                    if (!values.is_hidden) {
                      alert(
                        `ジャンル「${values.name}」に含まれるすべての商品が非表示になります`,
                      )
                    }
                    handleChange(e)
                  }}
                  value={values.is_hidden}
                />
                <FieldTitle
                  title={t('Hidden')}
                  tooltipText={t('ui_genres:Hint for hiding')}
                />
              </Box>
            </Box>
          </Box>

          <Divider sx={styles.divider} />

          {/* エラーメッセージ */}
          <FormikErrors touched={touched} errors={errors} />

          {/* 更新ボタン */}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isUpdating}
            >
              {isNew ? t('Add') : t('Update')}
            </Button>
          </Box>
        </CardContent>
      </Card>

      {/* カード外側の削除ボタン */}
      {!isNew && (
        <Box mt={2}>
          <Button
            onClick={deleteGenre}
            disabled={isDeleting}
            aria-label="delete"
            size="small"
            tabIndex={-1}
          >
            {t('Delete')}
          </Button>
        </Box>
      )}
    </form>
  )
}

const styles = {
  grid: {
    display: 'grid',
    gap: 3,
    gridTemplateColumns: '1fr',
    '@media (min-width: 960px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  cellGenre: { order: 1 },
  cellDescription: {
    order: 2,
    '@media (min-width: 960px)': {
      order: 3,
    },
  },
  cellLayout: {
    order: 3,
    '@media (min-width: 960px)': {
      order: 2,
    },
  },
  cellHidden: { order: 4 },
  divider: { mt: 2 },
} as const satisfies SxPropStyles
