import { SwingingBell } from '@/components/SwingingBell'
import { useAnnouncementManager } from '@/features/announcement/useAnnouncementManager'
import { useUserInfo } from '@/features/auth/state/authStatus.tsx'
import Logo from '@/features/layout/Logo'
import { defaultScreenRoute } from '@/routes/routes'
import { css } from '@emotion/react'
import { Box, Divider, Drawer, Hidden, List } from '@mui/material'
import React, { type FC, type ReactNode, useEffect } from 'react'
import {
  Clock as BackupIcon,
  Coffee as CoffeeIcon,
  BarChart as DashboradIcon,
  AlignLeft as DescriptionIcon,
  Download as DownloadIcon,
  Folder as FolderIcon,
  type Icon,
  PhoneCall,
  Code as QRCodeIcon,
  Settings as SettingsIcon,
  HelpCircle as SupportIcon,
  UploadCloud as UploadIcon,
} from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router'
import NavItem from './NavItem.tsx'

interface NavBarProps {
  onMobileClose: () => void
  openMobile: boolean
}

interface Item {
  href: string
  isExternalLink?: boolean
  icon: Icon
  title: ReactNode
}

interface Section {
  items: Item[]
  subheader: string
}

const styles = {
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100vh - 64px)',
  },
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation()
  // const { user } = useAuth();
  const { t } = useTranslation()
  const { isRead } = useAnnouncementManager()
  const { isAdmin } = useUserInfo()

  // サイドメニューの項目をクリックした後に、サイドメニューを閉じる
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const sections: Section[] = [
    {
      subheader: '___メニューの管理',
      items: [
        {
          title: t('Products'),
          icon: CoffeeIcon,
          href: '/app/management/menuitems',
        },
        {
          title: t('Genres'),
          icon: FolderIcon,
          href: '/app/management/genres',
        },
        {
          title: t('General settings'),
          icon: DescriptionIcon,
          href: '/app/management/menu',
        },
        {
          title: t('ui_deploy:Deployment'),
          icon: QRCodeIcon,
          href: '/app/management/previewMenu',
        },
      ],
    },
    {
      subheader: '高度',
      items: [
        {
          title: t('Backups'),
          icon: BackupIcon,
          href: '/app/backup',
        },
        {
          title: 'CSVインポート',
          icon: UploadIcon,
          href: '/app/csv-import',
        },
        {
          title: 'CSVエクスポート',
          icon: DownloadIcon,
          href: '/app/csv-export',
        },
      ],
    },
    {
      subheader: '___その他',
      items: [
        {
          title: (
            <span css={css({ display: 'flex', alignItems: 'center' })}>
              {t('Announcement')} {isRead ? null : <SwingingBell />}
            </span>
          ),
          icon: DashboradIcon,
          href: '/app/announcement',
        },
        {
          title: '使い方・マニュアル',
          icon: SupportIcon,
          href: 'https://fern-tank-8d6.notion.site/GoodMenu-a9c22262dcff43829e48e6c224aa6ba3',
          isExternalLink: true,
        },
        {
          title: t('Support'),
          icon: PhoneCall,
          href: '/app/support',
        },
      ],
    },
  ]

  if (isAdmin) {
    sections.push({
      subheader: '___管理者',
      items: [
        {
          title: '管理者',
          icon: SettingsIcon,
          href: '/app/admin',
        },
      ],
    })
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {/* モバイルのサイドメニュー最上段に表示するGoodMenuアイコン */}
      <Hidden lgUp={true}>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink
            to={defaultScreenRoute}
            css={css`
              line-height: 0;
            `}
          >
            <Logo width="36px" height="36px" />
          </RouterLink>
        </Box>
      </Hidden>

      <Divider />
      {sections.map((section, i) => {
        const isLast = i === sections.length - 1
        return (
          <React.Fragment key={section.subheader}>
            <Box px={2}>
              <List>
                {section.items.map((item) => (
                  <NavItem
                    href={item.href}
                    isExternalLink={item.isExternalLink}
                    icon={item.icon}
                    key={item.href}
                    title={item.title}
                  />
                ))}
              </List>
            </Box>
            {!isLast && <Divider />}
          </React.Fragment>
        )
      })}
      <Divider />
    </Box>
  )

  return (
    <>
      <Hidden lgUp={true}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{ sx: styles.mobileDrawer }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown={true}>
        <Drawer
          anchor="left"
          open={true}
          PaperProps={{ sx: styles.desktopDrawer }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

export default NavBar
