import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

export type Order = 'asc' | 'desc'
export type SortableFields = 'name' | 'description' | 'orderNum' | 'isHidden'

export type GenresQueryCondition = {
  order: Order
  orderBy: SortableFields
  searchWord: string
}

const initialCondition: GenresQueryCondition = {
  order: 'asc',
  orderBy: 'orderNum',
  searchWord: '',
}

const genresQueryConditionAtom = atom<GenresQueryCondition>(initialCondition)

/**
 * 商品一覧画面のクエリ条件を保持するカスタムフック
 */
export const useGenresQueryCondition = () => {
  const [condition, setCondition] = useAtom(genresQueryConditionAtom)

  /**
   * 現在セットされているソート列を考慮しつつ、いい感じにソートをセットする
   */
  const setOrderColumn = useCallback(
    (property: SortableFields) => {
      const isPropertyChanged = condition.orderBy !== property
      const order = (() => {
        if (isPropertyChanged) {
          return 'asc'
        }
        return condition.order === 'asc' ? 'desc' : 'asc'
      })()

      setCondition((prev) => ({
        ...prev,
        order,
        orderBy: property,
      }))
    },
    [setCondition, condition.orderBy, condition.order],
  )

  const setSearchWord = useCallback(
    (word: string) => {
      setCondition((prev) => ({
        ...prev,
        searchWord: word.trim(),
      }))
    },
    [setCondition],
  )

  return {
    condition,
    setOrderColumn, // TODO: 未利用 そのうち消す
    setSearchWord,
  }
}
