import { useUserInfo } from '@/features/auth/state/authStatus'
import { useMutationCreateBackup } from '@/features/backup/api/useMutationCreateBackup'
import { useQueryBackups } from '@/features/backup/api/useQueryBackups'
import { useConfirmCreateBackup } from '@/features/backup/hooks/useConfirmCreateBackup'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { planSettings } from '../account/planSettings.ts'

export const BackupViewHeader = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { confirmCreateBackup } = useConfirmCreateBackup()

  const userInfo = useUserInfo()
  const { data: backups } = useQueryBackups()
  const { isPending: isCreatingBackup, mutate: createBackup } =
    useMutationCreateBackup()

  const { maxBackupCount } = planSettings[userInfo.plan]
  const hitMaxBackupCount = backups.length >= maxBackupCount

  const onCreateBackupButtonClicked = async () => {
    if (hitMaxBackupCount) {
      alert(`バックアップ数の上限は${maxBackupCount}個までとなります`)
      return
    }

    const yes = await confirmCreateBackup()
    if (!yes) {
      return
    }

    const newBackupSeed = { memo: '' }
    createBackup(newBackupSeed, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.backups(),
        })
        enqueueSnackbar(t('ui_backup:Created backup'), {
          variant: 'success',
        })
      },
    })
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h3" color="textPrimary" noWrap={true}>
        {t('Backups')}
      </Typography>
      <Button
        color="secondary"
        disabled={isCreatingBackup}
        variant="contained"
        onClick={onCreateBackupButtonClicked}
        startIcon={
          <SvgIcon fontSize="small">
            <PlusCircleIcon />
          </SvgIcon>
        }
        sx={styles.button}
      >
        バックアップを作成
      </Button>
    </Box>
  )
}

const styles = {
  container: { display: 'flex', alignItems: 'center' },
  button: { marginLeft: 'auto' },
} as const satisfies SxPropStyles
