import { config } from '@/config.ts'
import { useQueryUserStats } from '@/features/admin/userStats/api/useQueryUserStats.ts'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Card, Typography } from '@mui/material'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { formatInTimeZone } from 'date-fns-tz'
import { ja } from 'date-fns/locale/ja'

export const UserStats = () => {
  const { data } = useQueryUserStats()

  return (
    <Card sx={styles.card}>
      <Typography variant={'h5'} mb={2}>
        ユーザー統計
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>最終ログイン</TableCell>
              <TableCell>Shop Name</TableCell>
              <TableCell>QR Code ID</TableCell>
              <TableCell>Stripe Customer ID</TableCell>
              <TableCell>Stripe Subscription ID</TableCell>
              <TableCell>Item Count</TableCell>
              <TableCell>Image Count</TableCell>
              <TableCell>登録日</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Owner ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.qrCodeId}>
                <TableCell sx={{ ...styles.cell }}>
                  {formatInTimeZone(
                    row.lastLogin,
                    'Asia/Tokyo',
                    'M月dd日 HH:mm',
                    { locale: ja },
                  )}
                </TableCell>
                <TableCell sx={{ ...styles.cell, ...styles.ellipsis }}>
                  {row.shopName}
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>
                  <a
                    target="_blank"
                    href={`${config.visitorSiteDomain}/qrcode/${row.qrCodeId}`}
                    rel="noreferrer"
                  >
                    {row.qrCodeId}
                  </a>
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>
                  <a
                    target="_blank"
                    href={`https://dashboard.stripe.com/customers/${row.stripeCustomerId}`}
                    rel="noreferrer"
                  >
                    {row.stripeCustomerId}
                  </a>
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>
                  <a
                    target="_blank"
                    href={`https://dashboard.stripe.com/subscriptions/${row.stripeSubscriptionId}`}
                    rel="noreferrer"
                  >
                    {row.stripeSubscriptionId}
                  </a>
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>{row.itemCount}</TableCell>
                <TableCell sx={{ ...styles.cell }}>{row.imageCount}</TableCell>
                <TableCell sx={{ ...styles.cell }}>
                  {formatInTimeZone(
                    row.createdAt,
                    'Asia/Tokyo',
                    'yyyy年M月dd日 HH:mm',
                    { locale: ja },
                  )}
                </TableCell>
                <TableCell sx={{ ...styles.cell }}>{row.email}</TableCell>
                <TableCell sx={{ ...styles.cell }}>{row.ownerId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

const styles = {
  card: {
    padding: 2,
  },
  cell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ellipsis: {
    maxWidth: 200, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
} satisfies SxPropStyles
