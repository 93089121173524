import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryProductStats = () => {
  return useSuspenseQuery({
    queryKey: queryKeys.statsProduct(),
    queryFn: () => {
      return trpcClient.stats.getProductStats.query()
    },
  })
}
