import { queryKeys } from '@/libraries/reactQuery/queryKeys.ts'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

type MutationVariables = {
  productId: string
  direction: 'up' | 'down' | 'top' | 'bottom'
}

/**
 * 商品の移動に関するロジックをまとめたカスタムフック
 */
export const useMoveProduct = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { isPending: isMoving, mutate: mutateMoveProduct } = useMutation({
    mutationFn: async ({
      productId,
      direction,
    }: MutationVariables): Promise<void> => {
      await trpcClient.product.moveProduct.mutate({
        productId,
        direction,
      })
    },
  })

  const moveProduct = async (
    menuitemId: string,
    direction: 'up' | 'down' | 'top' | 'bottom',
  ) => {
    mutateMoveProduct(
      {
        direction,
        productId: menuitemId,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.menuitemsAll(),
          })
        },
        onError: (e) => {
          const customError = unwrapCustomErrorPayload(e)
          if (customError && customError.type === 'moveProduct.alreadyAtEdge') {
            enqueueSnackbar('すでに現在のジャンルの先頭又は末尾にあります', {
              variant: 'warning',
            })
            return
          }
          enqueueSnackbar(t('Error occurred'), {
            variant: 'error',
          })
        },
      },
    )
  }

  return {
    moveProduct,
    isMoving,
  }
}
